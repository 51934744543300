export const onReady = () => {
    return new Promise((resolve) => {
        if (document.readyState === 'complete') {
            resolve();
            return;
        }
        document.addEventListener('readystatechange', () => {
            if (document.readyState !== 'complete') {
                return;
            }
            resolve();
        });
    });
};
